import { Component, ViewChild, TemplateRef } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { filter, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { RouterEvent, NavigationEnd, Router, ActivatedRoute, Data } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from './services/api/app.service';
import { StorageService } from '@sharedServices/storage.service';
import { AuthApiService } from './services/api/auth-api.service';

@Component({
    selector: 'sst-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  @ViewChild('childModal', { static: false }) childModal: TemplateRef<any>;


  constructor(
    public overlayContainer: OverlayContainer,
    private _router: Router,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private idle: Idle,
    private keepalive: Keepalive,
    private _authService: AuthApiService,
    private modalService: NgbModal,
    private appService: AppService,
    private _storage: StorageService,

  ) {

    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(3600); 
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(30); 
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      //this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.modalService.dismissAll();
      //this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      //this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      this.modalService.open(this.childModal);
    });


    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Your session will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })

    this.reset();
  }

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.modalService.dismissAll();
  }

  stay() {
    this.modalService.dismissAll();
    this.reset();
  }

  logout() {
    //set isBeingLoggedOut storage to disable dirty form check
    this._storage.setLocalStorage('isBeingLoggedOut', true);
    this.modalService.dismissAll();
    this._authService.logout();
  }
//https://blog.bitsrc.io/how-to-implement-idle-timeout-in-angular-af61eefdb13b

  async ngOnInit() {
    //this._router.events
    //  .pipe(
    //    filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent),
    //    map(() => this._route),
    //    map(route => {
    //      while (route.firstChild) route = route.firstChild;
    //      return route;
    //    }),
    //    filter(route => route.outlet === 'primary'),
    //    mergeMap(route => route.data),
    //    distinctUntilChanged())
    //  .subscribe(async (route: Data) => {
    //    if (route.title || route.breadcrumb) {
    //      this._titleService.setTitle(`${route.title || route.breadcrumb} | BeyondSST`);
    //    }
    //  });
  }
}
